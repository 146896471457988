import { APPS } from './constants';
import { useState } from 'react';

export const getApp = () => {
  const subdomain = getSubdomain(window.location.hostname);

  const main = APPS.find((app) => app.main);
  if (!main) throw new Error('Must have main app');

  if (subdomain === 'enterprise') return main.app;

  const app = APPS.find((app) => subdomain === app.subdomain);
  if (!app) return main.app;

  return app.app;
};

export const getSubdomain = (location: string) => {
  const locationParts = location.split('.');

  let sliceTill = -2;

  const isLocalHost = locationParts.slice(-1)[0] === 'recyclium.io';
  // 'localhost' ||

  if (isLocalHost) sliceTill = -1;

  return locationParts.slice(0, sliceTill).join('');
};

export const isNumeric = (arg: string) => !/^\d*$/.test(arg);

export const isValidEmail = (arg: string) => !/\S+@\S+\.\S+/.test(arg);
