// Utils
import { deleteAccessTokenCookie, setAccessTokenCookieData } from '../utils/cookie';
import axios from 'axios';
import getHost from '../utils/getHost';

export const apiLogout = () => {
  deleteAccessTokenCookie();
  window.location.href = '/';
};

type TApiLogin = {
  email: string | null;
  password: string | null;
};

export const apiLogin = async (payload: TApiLogin) => {
  const { data } = await axios.post(`${getHost()}/api/v1/auth/login`, payload, {
    withCredentials: true,
  });

  const { token, expiresInDays } = data.jwtToken;
  setAccessTokenCookieData(token, expiresInDays);

  return { data: data.data, jwtToken: token };
};
