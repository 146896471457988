import { AppRouter, RecyclerRouter, WastemnRouter } from '../routes';
export const APPS = [
  {
    subdomain: 'enterprise', // www
    app: AppRouter,
    main: true,
  },
  {
    subdomain: 'recycler',
    app: RecyclerRouter,
    main: false,
  },
  {
    subdomain: 'wastemn',
    app: WastemnRouter,
    main: false,
  },
];
