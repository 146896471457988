import { useContext, createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiLogin } from '../api/login';
import UserModel from '../@types/models/UserModel';
import { getUserById } from '../api/user';
import { CredentialResponse, GoogleOAuthProvider } from '@react-oauth/google';
import { googleAuthenticate } from '../api/base';
import { getAccessTokenCookieData } from '../utils/cookie';
import UserContext from './UserContext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getApp } from '../utils/helpers';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

const googleClientId = '887300661713-h3jh0bgd708r2dt9gc0josdsdnkku4ic.apps.googleusercontent.com';

// ----------------------------------------------------------------------

const AuthContext = createContext({});

const AuthProvider = ({ children }: any) => {
  const CurrentApp = getApp();
  const { enqueueSnackbar } = useSnackbar();
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const [userModel, setUserModel] = useState<UserModel | any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const getUser = async (id: string) => {
    try {
      const user = await getUserById(id);
      if (user.id !== 'undefined') {
        setUserModel(user);
      } else {
        setUserModel(null);
      }
    } catch (err) {
      console.error('Failed to get user:', err);
      setUserModel(null);
    } finally {
      setLoading(false);
    }
  };

  const googleSignInSuccess = (credentialResponse: CredentialResponse): void => {
    googleAuthenticate(credentialResponse).then(async (res) => getUser(res.jwtToken));
  };

  const loginAction = async (payload: any) => {
    try {
      const { data, jwtToken } = await apiLogin(payload);

      if (data.isSigned) {
        setUserModel(data);
        setToken(jwtToken);

        navigate('/dashboard', { replace: true });
        return;
      }
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      // get the authToken from the browser
      const token: any = getAccessTokenCookieData();

      if (token) {
        // fetch from the api
        await getUser(token);
      } else {
        setLoading(false);
      }
    };

    fetchUserData().catch((err) => console.log(err));
  }, []);

  return (
    <AuthContext.Provider value={{ token, userModel, loginAction }}>
      <GoogleOAuthProvider key={userModel?.id} clientId={googleClientId}>
        <UserContext.Provider value={{ userModel, googleSignInSuccess }}>
          {loading ? (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <CurrentApp userModel={userModel} />
          )}
        </UserContext.Provider>
      </GoogleOAuthProvider>
      {/*{children}*/}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
